import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { ProductsSelector } from "../library/selector/Products";
import { LocationSelector } from "../library/selector/Location";
import { DRAFT_ENTITY } from "../utils/mutations";
import { formatter } from "../utils/helpers";

export default function Order() {
  const [items, setItems] = useState([]);
  const [location, setLocation] = useState(null);

  const clear = () => {
    setItems([]);
    setLocation(null);
  };

  return (
    <UserLayout title="Order | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Order Products"></SectionHead>
        {/* information */}
        <ProductsSelector cart={items} setCart={setItems} />
        {/* location */}
        {items.length > 0 && (
          <LocationSelector
            label={`Select a pickup location`}
            selection={location}
            setSelection={setLocation}
          />
        )}
        {/* payment */}
        {items.length > 0 && location && (
          <CheckoutOrderForm items={items} location={location} clear={clear} />
        )}
      </section>
    </UserLayout>
  );
}

function CheckoutOrderForm(props) {
  const { items, location, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);

  const { handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        // navigate to the transactions page with the transaction id
        navigate(`/transactions/${data.transaction}`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = () => {
    const input = {
      type: "ORDER",
      order: {
        userId: user.data.id,
        locationId: location.id,
        items: items.map((item) => ({
          product: item.product,
          quantity: item.quantity,
          price: item.price,
        })),
        total: parseInt(amount),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  useEffect(() => {
    let sum = 0;
    items.forEach((item) => {
      sum += item.price * item.quantity;
    });

    setAmount(sum);
  }, [items]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
        >
          Finalise Order, Pay {formatter.format(amount / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Clear
      </button>
    </>
  );
}
