import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { StudentSelector } from "../library/selector/Student";
import { WorkshopSelector } from "../library/selector/Workshop";
import { DRAFT_ENTITY } from "../utils/mutations";
import { formatter } from "../utils/helpers";

export default function Admission() {
  const [student, setStudent] = useState(null);
  const [workshopInfo, setWorkshopInfo] = useState(null);

  const clear = () => {
    setStudent(null);
    setWorkshopInfo(null);
  };

  return (
    <UserLayout title="Workshop Admission | Swastik App">
      {/* heading */}
      <SectionHead heading="Workshop Admission" />
      {/* information */}
      <SectionInfo>
        <p>
          <strong>Workshop:</strong> can be used to admit for all remaining
          workshop sessions.
        </p>
        <p>
          <i>Please note that all admissions are final and non-transferable.</i>
        </p>
      </SectionInfo>
      <section>
        {/* student */}
        <StudentSelector
          selection={student}
          setSelection={setStudent}
          type="admission"
        />
        {/* workshop */}
        {student && (
          <WorkshopSelector
            student={student}
            selection={workshopInfo}
            setSelection={setWorkshopInfo}
          />
        )}
        {/* add admission form */}
        {student && workshopInfo && (
          <AddAdmissionForm
            student={student}
            workshopInfo={workshopInfo}
            clear={clear}
          />
        )}
      </section>
    </UserLayout>
  );
}

function AddAdmissionForm(props) {
  const { student, workshopInfo, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [amount, setAmount] = useState(0);

  const { handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        // navigate to the transactions page with the transaction id
        navigate(`/transactions/${data.transaction}`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = () => {
    const input = {
      type: "ADMISSION",
      admission: {
        userId: user.data.id,
        studentId: student.id,
        workshopId: workshopInfo.id,
        total: parseInt(amount),
      },
    };

    draftEntity({ variables: { ...input } });
  };

  useEffect(() => {
    const total = workshopInfo?.sessionsAvailable * workshopInfo?.price;

    setAmount(total < 0 ? 0 : total);
  }, [workshopInfo]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={loading || amount <= 0}
        >
          Finalise Admission, Pay {formatter.format(amount / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Clear
      </button>
    </>
  );
}
