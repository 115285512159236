import { useEffect, useState } from "react";
import clsx from "clsx";
import { formatter } from "../../utils/helpers";

const titleValues = {
  Enrolment: "Class Enrolment",
  Pass: "Class Pass",
  Order: "Products Order",
  Training: "Private Training",
  Performance: "Event Performance",
  Hire: "Hall Hire",
  Admission: "Workshop Admission",
};

const statusTagColor = (str) => {
  switch (str) {
    case "PENDING":
      return `bg-warning`;
    case "SUCCESSFUL":
      return `bg-green-500`;
    default:
      return `bg-base-300`;
  }
};

export const SummarySection = (props) => {
  const { transaction } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    if (transaction) {
      const information = transaction[transaction.type.toLowerCase()];

      const props = {};

      const name = information?.name ?? null;
      const number = information?.number ? `#${information.number}` : null;

      props.title = titleValues[transaction.type] ?? "Transaction";
      props.description = name ?? number ?? "-";
      props.amount = transaction.amount;
      props.discount = transaction.discount;
      props.refunded = transaction.refunded;
      props.deposit = transaction.deposit;
      props.redeemed = transaction.redeemed;
      props.status = transaction.status;

      setData(props);
    }
  }, [transaction]);

  return (
    <>
      {/* Summary section */}
      {data && (
        <>
          <div className="">
            <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 p-6 bg-white rounded-md">
              <div className="flex flex-col gap-1">
                <h2 className="text-xl font-semibold">{data.title ?? "-"}</h2>
                <p>{data.description ?? "-"}</p>
                <p className="space-x-2">
                  <span>
                    <strong>Amount:</strong>{" "}
                    {formatter.format(data.amount / 100)}{" "}
                    {data.amount > 0 && <small>incl. of GST</small>}
                  </span>
                </p>
                {data.discount > 0 && (
                  <p className="space-x-2 space-y-2 text-xs">
                    <span>
                      Includes discount of{" "}
                      <strong>{formatter.format(data.discount / 100)}</strong>
                    </span>
                  </p>
                )}
                {data.redeemed > 0 && (
                  <p className="space-x-2 space-y-2 text-xs">
                    <span>
                      Voucher redeemed worth{" "}
                      <strong>{formatter.format(data.redeemed / 100)}</strong>
                    </span>
                  </p>
                )}

                {data.deposit?.amount > 0 && (
                  <p className="space-x-2 space-y-2 text-xs">
                    <span>
                      Includes deposit of{" "}
                      <strong>
                        {formatter.format(data.deposit.amount / 100)}
                      </strong>
                    </span>
                  </p>
                )}

                {data.refunded > 0 && (
                  <p className="space-x-2 space-y-2 text-sm">
                    <span className="text-red-700">
                      Amount refunded{" "}
                      <strong>{formatter.format(data.refunded / 100)}</strong>
                    </span>
                    {!!data.deposit.returned && (
                      <span className="text-green-700">(deposit returned)</span>
                    )}
                  </p>
                )}
              </div>
              <div>
                <span
                  className={clsx(
                    "text-xs py-1 px-2 rounded-md text-white",
                    statusTagColor(data.status)
                  )}
                >
                  {data.status}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
