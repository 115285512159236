import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import { TRANSACTION_DETAILS } from "../utils/queries";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SummarySection } from "../library/sections/Summary";
import { PaymentSection } from "../library/sections/Payment";
import { TransactionsList } from "../library/lists/Transactions";

export default function Transactions() {
  const { id } = useParams();

  return id ? <Details id={id} /> : <List />;
}

function Details(props) {
  const { id } = props;

  const navigate = useNavigate();

  const [secret, setSecret] = useState(null);
  const [amount, setAmount] = useState(0);
  const [entity, setEntity] = useState(null);
  const [isPending, setIsPending] = useState(false);

  const { data: information, refetch } = useQuery(TRANSACTION_DETAILS, {
    variables: { id },
    onCompleted: (data) => {
      if (data.transaction) {
        const { transaction } = data;

        if (transaction.status === "PENDING") setIsPending(true);
        if (transaction.method === "STRIPE" && !!transaction.secret)
          setSecret(transaction.secret);
        if (!!transaction.amount) setAmount(transaction.amount);
        if (!!transaction.type && !!transaction.entity) {
          setEntity({
            type: transaction.type.toUpperCase(),
            entityId: transaction.entity,
          });
        }
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Error fetching transaction details");
      navigate("/transactions");
    },
    fetchPolicy: "network-only",
  });

  const paymentPolicy = {
    title: "Payment Policy Notice",
    content:
      "At the Swastik Institute of Dance, all payments are final, non-refundable, non-transferable, and non-extendable under any circumstances, including inability to attend classes due to travel, illness, or any other reason. This policy is crucial for sustaining our operational costs. By making this payment, you acknowledge and agree to these terms without exception.",
  };

  return (
    <UserLayout
      title={`${
        information?.transaction?.type ?? ""
      } Transaction | Swastik App`}
    >
      {information && (
        <section>
          {/* transaction */}
          <SectionHead
            heading={
              isPending
                ? `Finalise Payment`
                : `${information.transaction?.type} Transaction`
            }
          >
            <button className="btn btn-outline" onClick={() => navigate(-1)}>
              Back
            </button>
          </SectionHead>
          <div className="flex flex-col py-4 gap-4">
            <>
              {!!information.transaction && (
                <SummarySection
                  transaction={information.transaction}
                  refetch={refetch}
                />
              )}
            </>

            <>
              {!!secret && (
                <PaymentSection
                  clientSecret={secret}
                  amount={amount}
                  entity={entity}
                  policy={paymentPolicy}
                />
              )}
            </>
          </div>
        </section>
      )}
      {information && !information.transaction && (
        <SectionHead heading="Transaction not found">
          <button
            className="btn btn-outline"
            onClick={() => navigate("/transactions")}
          >
            Back
          </button>
        </SectionHead>
      )}
    </UserLayout>
  );
}

function List() {
  return (
    <UserLayout title="Transactions | Swastik App">
      <section>
        {/* list */}
        <TransactionsList />
      </section>
    </UserLayout>
  );
}
