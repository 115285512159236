import moment from "moment";

export const formatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
});

export const compactFormatter = new Intl.NumberFormat("en-AU", {
  style: "currency",
  currency: "AUD",
  maximumFractionDigits: 0,
  compactDisplay: "short",
  notation: "compact",
});

export const upperFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatMobile = (number) => {
  const trimmedNumber = number.replace(/\s/g, "");
  const startIndex = trimmedNumber.split("").findIndex((char) => char === "4");
  const formattedNumber = trimmedNumber.slice(startIndex, startIndex + 9);
  const countryCode = "+61";

  return countryCode.concat(formattedNumber);
};

export const parseDateTime = (datetimeString) => {
  // Split the datetimeString into date and time parts
  var parsedDatetime = moment(datetimeString, "YYYY-MM-DD hh:mm A");

  // Format the parsed datetime to ISO 8601 format
  var isoDateTime = parsedDatetime.format("YYYY-MM-DDTHH:mm");

  return isoDateTime;
};

export const convertUnixTimestamp = (datetimeString) => {
  // convert unix timestamp to milliseconds and then create a new Date object
  const date = new Date(datetimeString * 1);
  // Split the datetimeString into date and time parts
  const parsedDatetime = moment(date);
  const isoDateTime = parsedDatetime.format("DD-MM-YYYY hh:mm A");

  //   new Date(transaction.created * 1000).toLocaleString(
  //     "en-AU",
  //     {
  //       day: "numeric",
  //       month: "numeric",
  //       year: "numeric",
  //       hour: "numeric",
  //       minute: "numeric",
  //       hour12: true,
  //     }
  //   );

  return isoDateTime;
};

export const toAmPm = (time) => {
  return moment(time, "HH:mm").format("hh:mm A");
};

export const toDDMMYYYY = (date) => {
  return moment(date).format("DD-MM-YYYY");
};
