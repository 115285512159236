import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import toast from "react-hot-toast";
import clsx from "clsx";
import { AuthContext } from "../../context/authContext";
import { SectionHead } from "../sections/Heading";
import { LIST_TRANSACTIONS } from "../../utils/queries";
import { formatter, convertUnixTimestamp } from "../../utils/helpers";

const titleValues = {
  Enrolment: "Class Enrolment",
  Pass: "Class Pass",
  Order: "Products Order",
  Training: "Private Training",
  Performance: "Event Performance",
  Hire: "Hall Hire",
  Admission: "Workshop Admission",
};

const statusTagColor = (str) => {
  switch (str) {
    case "PENDING":
      return `bg-warning`;
    case "SUCCESSFUL":
      return `bg-green-500`;
    default:
      return `bg-base-300`;
  }
};

export const TransactionsList = (props) => {
  const {
    title = "Transactions",
    limit,
    status = ["PENDING", "AUTHORISED", "SUCCESSFUL", "CANCELLED"],
    type,
    timestamp = true,
    polling = false,
  } = props;

  const [list, setList] = useState([]);

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  useQuery(LIST_TRANSACTIONS, {
    variables: {
      userId: user.data.id,
      status,
      ...(type && { type }),
      ...(limit && { limit }),
    },
    onCompleted: (data) => {
      if (data.transactions?.list.length > 0) {
        const formattedList = data.transactions.list.map((transaction) => {
          const information = transaction[transaction.type.toLowerCase()];
          const props = {};
          const name = information?.name ?? null;
          const number = information?.number ? `#${information.number}` : null;
          props.id = transaction.id;
          props.title = titleValues[transaction.type] ?? "Transaction";
          props.description = name ?? number ?? "-";
          props.amount = transaction.amount;
          props.discount = transaction.discount;
          props.refunded = transaction.refunded;
          props.deposit = transaction.deposit;
          props.status = transaction.status;
          props.datetime = convertUnixTimestamp(transaction.created);

          return { ...props };
        });
        setList(formattedList);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
    ...(polling && { pollInterval: 10000 }),
  });

  return (
    <section>
      <SectionHead heading={title} />
      <ul className="flex flex-col gap-2 py-4">
        {list.length > 0 ? (
          list.map((payment) => (
            <li
              key={payment.id}
              className="flex flex-col md:flex-row md:justify-between md:items-center px-6 py-4 gap-4 bg-white rounded-xl shadow-lg"
            >
              <div className="">
                <h2 className="text-xl font-semibold">
                  {payment.title ?? "-"}
                </h2>
                <p>{payment.description ?? "-"}</p>
                <p className="space-x-2">
                  <span>
                    <strong>Amount:</strong>{" "}
                    {formatter.format(payment.amount / 100)}{" "}
                    {payment.amount > 0 && <small>incl. of GST</small>}
                  </span>
                </p>
                {timestamp && (
                  <small className="text-gray-500">{payment.datetime}</small>
                )}
              </div>
              <div className="flex flex-row items-center gap-2">
                {payment.status !== "PENDING" && (
                  <div className="flex gap-2">
                    <div>
                      <span
                        className={clsx(
                          "text-xs py-1 px-2 rounded-md text-white",
                          statusTagColor(payment.status)
                        )}
                      >
                        {payment.status}
                      </span>
                    </div>
                    <button
                      className={clsx("btn btn-xs btn-ghost")}
                      onClick={() => navigate(`/transactions/${payment.id}`)}
                    >
                      View Summary
                    </button>
                  </div>
                )}
                {payment.status === "PENDING" && (
                  <button
                    className={clsx("btn btn-sm btn-primary w-full")}
                    onClick={() => navigate(`/transactions/${payment.id}`)}
                  >
                    View & Pay
                  </button>
                )}
              </div>
            </li>
          ))
        ) : (
          <p className="text-center p-8">No transactions found</p>
        )}
      </ul>
      {!!limit && list.length > 0 && (
        <div className="w-full flex justify-center items-center">
          <button
            className="btn btn-ghost btn-md"
            onClick={() => navigate("/transactions")}
          >
            View All
          </button>
        </div>
      )}
    </section>
  );
};
