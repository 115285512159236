import logo from "../logo.png";
import { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { Helmet } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Squash as Hamburger } from "hamburger-react";
import clsx from "clsx";
import {
  HomeIcon,
  RectangleStackIcon,
  SwatchIcon,
  ShoppingBagIcon,
  HandRaisedIcon,
  MapPinIcon,
  KeyIcon,
  WalletIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";

export const UserLayout = (props) => {
  const { user } = useContext(AuthContext);

  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);

  const year = new Date().getFullYear();

  const navigation = [
    {
      name: "Class Enrolment",
      link: "/enrolment",
      icon: <RectangleStackIcon className="w-6 h-6" />,
    },
    {
      name: "Class Pass",
      link: "/pass",
      icon: <SwatchIcon className="w-6 h-6" />,
    },
    {
      name: "Products Order",
      link: "/order",
      icon: <ShoppingBagIcon className="w-6 h-6" />,
    },
    {
      name: "Private Training",
      link: "/training",
      icon: <HandRaisedIcon className="w-6 h-6" />,
    },
    {
      name: "Event Performance",
      link: "/performance",
      icon: <MapPinIcon className="w-6 h-6" />,
    },
    {
      name: "Hall Hire",
      link: "/hire",
      icon: <KeyIcon className="w-6 h-6" />,
    },
    {
      name: "Workshop Admission",
      link: "/admission",
      icon: <AcademicCapIcon className="w-6 h-6" />,
    },
    {
      name: "Transactions",
      link: "/transactions",
      icon: <WalletIcon className="w-6 h-6" />,
    },
  ];

  return (
    <div className="bg-base-100">
      <header className="sticky top-0 z-10 shadow-lg">
        <Toaster />
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
        <div className="navbar bg-neutral py-4 px-4">
          <div className="relative container flex justify-between max-w-7xl mx-auto">
            <div className="flex z-50">
              <Link to="/home">
                <img src={logo} alt="App logo" className="w-48" />
              </Link>
            </div>
            <div>
              {user ? (
                <UserNav
                  open={isOpen}
                  toggle={toggle}
                  profile={user.data}
                  menu={navigation}
                />
              ) : (
                <PublicNav />
              )}
            </div>
          </div>
        </div>
      </header>
      <main>
        <div className="container max-w-7xl mx-auto p-4 py-8">
          {props.children}
        </div>
      </main>
      <footer>
        <div className="footer footer-center max-w-7xl p-4 text-base-content border-t-2 mx-auto">
          <div>
            <p>
              Copyright © {year} - All right reserved by{" "}
              <a
                href="https://swastikdance.com.au"
                target="_blank"
                rel="noreferrer"
              >
                Swastik Institute of Dance
              </a>{" "}
              - Powered by{" "}
              <a
                href="https://logicsync.com.au"
                target="_blank"
                rel="noreferrer"
              >
                LogicSync
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

function UserNav(props) {
  const { open, toggle, profile, menu } = props;

  const { logout } = useContext(AuthContext);

  return (
    <div className="relative flex flex-row gap-2 items-center">
      <NavLink
        to="/home"
        id="home-btn"
        className="rounded-full p-2 z-50"
        onClick={() => {
          if (open) toggle();
        }}
      >
        <HomeIcon className="w-5 h-5" />
      </NavLink>
      <div className="hover:bg-white/5 focus:bg-white/5 rounded-full z-50">
        <Hamburger
          toggled={open}
          toggle={toggle}
          color={"#fff"}
          size={24}
          rounded
          label="Show menu"
        />
      </div>

      {/* add transition slide in - slide out */}
      <div
        className={clsx(
          "fixed top-0 left-0 w-full h-full z-40 bg-black/50",
          open ? "block" : "hidden"
        )}
        onClick={toggle}
      >
        {/* drawer slide out from right with transition */}
        <div
          className={clsx(
            "fixed top-0 right-0 w-full sm:w-96 h-full z-50 bg-neutral shadow-lg overflow-y-scroll",
            open ? "animate-slide-in-right" : "animate-slide-out-right"
          )}
        >
          <div className="pt-24 px-2 pb-4 flex flex-col justify-between h-full">
            {/* top */}
            <nav>
              <ul className="menu p-4 overflow-y-auto bg-neutral text-neutral-content uppercase tracking-wider text-sm">
                {menu.map((item, index) => (
                  <li key={index}>
                    <NavLink to={item.link} activeclassname="active">
                      {item.icon}
                      <span className="ml-2">{item.name}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </nav>
            {/* bottom */}
            <div className="border-t border-white/20 py-4">
              <div className="flex flex-row gap-4 px-6">
                <div className="bg-white/40 w-16 h-16 rounded-full flex justify-center items-center">
                  {profile && (
                    <p className="text-3xl font-semibold">
                      {profile.email.slice(0, 1).toUpperCase()}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-2 items-start justify-center">
                  <div>
                    <p className="text-base-300 tracking-wide px-1">
                      {profile.email}
                    </p>
                  </div>
                  <div className="flex flex-row gap-2">
                    <Link to="/profile" className="btn btn-xs bg-white/10 px-3">
                      My Profile
                    </Link>
                    <button
                      onClick={() => logout()}
                      className="btn btn-xs bg-white/10 px-3"
                    >
                      Log Out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PublicNav(props) {
  return (
    <div className="flex-none">
      <Link to="/login" className="btn btn-primary">
        Login
      </Link>
    </div>
  );
}
