import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { useForm, useWatch, useFormState } from "react-hook-form";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { REQUEST_ENTITY } from "../utils/mutations";

export default function Performance() {
  return (
    <UserLayout title="Event Performance | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Event Performance"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            You can request to book a performance for an event or a special
            occasion. Where we can provide a group of dancers based on your
            requirements.
          </p>
          <p>
            <strong>Step 1:</strong> Fill in the details of the performance
            request.
          </p>
          <p>
            <strong>Step 2:</strong> Submit the request for review. On
            acceptance we will schedule the performance, you will receive a
            payment request.
          </p>
          <p>
            <strong>Step 3:</strong> You'll find an option to pay for the
            performance on the Home page, under the Awaiting Payments section.
            Once the payment is confirmed, the performance will be scheduled.
          </p>
          <p>
            <i>
              <strong>Please note</strong> that once this request is submitted,
              we will review and plan performance based on your inputs. Only
              once the payment is confirmed the performance will be scheduled.
              We may contact you for further details if required.
            </i>
          </p>
        </SectionInfo>
        {/* submit information */}
        <SubmitInformation />
      </section>
    </UserLayout>
  );
}

function SubmitInformation(props) {
  const { user } = useContext(AuthContext);

  // 2 days from today
  const minDate = new Date();
  minDate.setDate(minDate.getDate() + 2);

  const { control, register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const { isValid } = useFormState({ control });

  const requirementsEntered = useWatch({
    control,
    name: "performance.requirements",
  });

  const [requestEntity, { loading }] = useMutation(REQUEST_ENTITY, {
    onCompleted: (data) => {
      if (data.entity) {
        reset();

        toast.success("Request submitted successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = (data) => {
    const { performance } = data;

    const input = {
      type: "PERFORMANCE",
      performance: {
        userId: user.data.id,
        requirements: performance.requirements,
        dancers: parseInt(performance.dancers),
        date: performance.date,
        time: performance.time,
        venue: {
          name: performance.venue.name,
          address: performance.venue.address,
          suburb: performance.venue.suburb,
          postcode: performance.venue.postcode,
          state: performance.venue.state,
        },
        notes: performance.notes,
      },
    };

    requestEntity({ variables: { ...input } });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* text purpose */}
        <div className="border-b-2 pt-2 pb-4 mb-4">
          <label className="label">
            <span className="label-text font-semibold">
              Performance Requirements
            </span>
          </label>
          <textarea
            className="textarea h-24 textarea-bordered bg-white w-full"
            placeholder="Enter requirements for the performance (dancers, props, etc.)"
            {...register("performance.requirements", {
              required: true,
            })}
          ></textarea>
        </div>
        {requirementsEntered && (
          <>
            <div className="flex flex-col sm:flex-row gap-4">
              {/* number of dancers */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">
                    Number of Dancers
                  </span>
                </label>
                <input
                  type="number"
                  className="input input-bordered bg-white w-full"
                  placeholder="Enter the number of dancers required for the performance"
                  defaultValue={2}
                  min={2}
                  {...register("performance.dancers", {
                    required: true,
                    min: 2,
                  })}
                />
              </div>
              {/* date */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">Event Date</span>
                </label>
                <input
                  type="date"
                  className="input input-bordered bg-white w-full"
                  min={minDate.toISOString().split("T")[0]}
                  {...register("performance.date", {
                    required: true,
                  })}
                />
              </div>
              {/* time */}
              <div className="basis-1/3">
                <label className="label">
                  <span className="label-text font-semibold">Event Time</span>
                </label>
                <input
                  type="time"
                  className="input input-bordered bg-white w-full"
                  {...register("performance.time", {
                    required: true,
                  })}
                />
              </div>
            </div>
            {/* vennue inputs */}
            <div>
              {/* input venue name */}
              <label className="label">
                <span className="label-text font-semibold">Venue Name</span>
              </label>
              <input
                type="text"
                className="input input-bordered bg-white w-full"
                placeholder="Enter the name of the venue for the event (optional)"
                {...register("performance.venue.name")}
              />
              {/* venue street address */}
              <div>
                <label className="label">
                  <span className="label-text font-semibold">
                    Venue Street Address
                  </span>
                </label>
                <input
                  type="text"
                  className="input input-bordered bg-white w-full"
                  placeholder="Enter the address of the event"
                  {...register("performance.venue.address", {
                    required: true,
                  })}
                />
              </div>
              <div className="flex gap-4">
                {/* venue suburb */}
                <div className="basis-1/2">
                  <label className="label">
                    <span className="label-text font-semibold">
                      Venue Suburb
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-white w-full"
                    placeholder="Enter the suburb of the event"
                    {...register("performance.venue.suburb", {
                      required: true,
                    })}
                  />
                </div>
                {/* venue postcode */}
                <div className="basis-1/2">
                  <label className="label">
                    <span className="label-text font-semibold">
                      Venue Postcode
                    </span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered bg-white w-full"
                    placeholder="Enter the postcode of the event"
                    minLength={4}
                    maxLength={4}
                    {...register("performance.venue.postcode", {
                      required: true,
                    })}
                  />
                </div>
              </div>
              {/* vennue state (AU States Only) */}
              <div>
                <label className="label">
                  <span className="label-text font-semibold">Venue State</span>
                </label>
                <select
                  className="select select-bordered bg-white w-full"
                  defaultValue={"NSW"}
                  {...register("performance.venue.state", {
                    required: true,
                  })}
                >
                  <option value="">Select State</option>
                  <option value="ACT" disabled>
                    Australian Capital Territory
                  </option>
                  <option value="NSW">New South Wales</option>
                  <option value="NT" disabled>
                    Northern Territory
                  </option>
                  <option value="QLD" disabled>
                    Queensland
                  </option>
                  <option value="SA" disabled>
                    South Australia
                  </option>
                  <option value="TAS" disabled>
                    Tasmania
                  </option>
                  <option value="VIC" disabled>
                    Victoria
                  </option>
                  <option value="WA" disabled>
                    Western Australia
                  </option>
                </select>
              </div>
            </div>
            {/* text notes */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">
                  Additional Notes
                </span>
              </label>
              <textarea
                className="textarea h-24 textarea-bordered bg-white w-full"
                placeholder="Enter any additional notes or contact info for the performance"
                {...register("performance.notes")}
              ></textarea>
            </div>
            {/* checkbox terms */}
            <div className="flex mt-4">
              <label className="cursor-pointer label">
                <input
                  type="checkbox"
                  className="checkbox"
                  {...register("performance.terms", {
                    required: true,
                  })}
                />
                <span className="ml-2 label-text font-medium">
                  By submitting this request, I confirm that I've read and I
                  agree to the terms and conditions of the event performance set
                  by Swastik Dance Academy (
                  <a
                    href="/files/performance-terms.pdf"
                    target="_blank"
                    aria-label="read the terms"
                    className="underline underline-offset-2"
                  >
                    click here
                  </a>{" "}
                  to read to the document). I understand that this is a request
                  for performance and not a confirmation of the same.
                </span>
              </label>
            </div>
          </>
        )}
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={!isValid}
        >
          Submit Request
        </button>
      </form>
      {/* cancel */}
      {requirementsEntered && (
        <button
          className="btn btn-outline w-full mt-2"
          onClick={() => {
            reset();
          }}
        >
          Clear
        </button>
      )}
    </>
  );
}
