import { useEffect, useState } from "react";
import { UserLayout } from "../layouts/User";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";

function Success() {
  const [searchParams] = useSearchParams();

  const stripe = useStripe();

  const navigate = useNavigate();

  const [message, setMessage] = useState(null);

  const status = searchParams.get("redirect_status");

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment successful!");
          toast.success("Payment successful!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          toast("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          toast.error("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          toast.error("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <UserLayout title="Enrolment Successful| Swastik App">
      {status === "succeeded" ? (
        <div>
          <div className="flex flex-col max-w-sm mx-auto justify-center items-center space-y-4 mb-8">
            <div className="text-accent">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </div>
            <div>
              <h1 className="text-4xl font-bold text-accent">Thank You</h1>
            </div>
            <div>
              <p className="text-center">
                {message ?? "Free transaction succeeded!"}
              </p>
            </div>
            <div>
              <button className="btn" onClick={() => navigate(-1)}>
                Back to previous page
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col max-w-sm mx-auto justify-center items-center space-y-4 mb-8">
          <div>
            <Link to="/home" className="btn">
              Back to home
            </Link>
          </div>
        </div>
      )}
    </UserLayout>
  );
}

export default Success;
