import { useState, useEffect, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { LocationSelector } from "../library/selector/Location";
import { HallSelector } from "../library/selector/Hall";
import { ScheduleSelector } from "../library/selector/Schedule";
import { DRAFT_ENTITY } from "../utils/mutations";
import { formatter } from "../utils/helpers";

export default function Hire() {
  const [location, setLocation] = useState(null);
  const [hall, setHall] = useState(null);
  const [duration, setDuration] = useState(0);
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    if (location) setHall(null);
  }, [location]);

  return (
    <UserLayout title="Hall Hire | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Hall Hire"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            You can request to book private training sessions to improve skills
            for a dance form, prepare for an event performance as an individual
            or for a group.
          </p>
          <p>
            <strong>Step 1:</strong> Select a primary student account for the
            training. And fill in the details of the training request.
          </p>
          <p>
            <strong>Step 2:</strong> Submit the request for review and payment.
            On acceptance we will create the training plan, you will receive a
            payment request.
          </p>
          <p>
            <strong>Step 3:</strong> You'll find an option to pay for the
            training sessions on the Home page, under the Awaiting Payments
            section. Once the payment is confirmed, the training sessions will
            be scheduled.
          </p>
          <p>
            <i>
              <strong>Please note</strong> that once this request is submitted,
              we will review and plan sessions based on your inputs. Only once
              the payment is confirmed the sessions will be scheduled. We may
              contact you for further details if required.
            </i>
          </p>
        </SectionInfo>
        <LocationSelector
          label={`Select a location`}
          selection={location}
          setSelection={setLocation}
        />
        {location && (
          <HallSelector
            label={`Select a hall`}
            location={location}
            selection={hall}
            setSelection={setHall}
          />
        )}
        {location && hall && (
          <ScheduleSelector
            location={location}
            hall={hall}
            selection={schedule}
            setSelection={setSchedule}
            duration={duration}
            setDuration={setDuration}
          />
        )}
        {location && hall && (
          <AddHireForm
            location={location}
            hall={hall}
            duration={duration}
            schedule={schedule}
            clear={() => {
              setLocation(null);
              setHall(null);
              setSchedule([]);
            }}
          />
        )}
      </section>
    </UserLayout>
  );
}

function AddHireForm(props) {
  const { location, hall, duration, schedule, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [total, setTotal] = useState(0);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({
    mode: "onTouched",
  });

  const [draftHireEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        reset(); // reset the data
        clear(); // clear the form
        // navigate to the transactions page with the transaction id
        navigate(`/transactions/${data.transaction}`);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = (data) => {
    const input = {
      type: "HIRE",
      hire: {
        userId: user.data.id,
        locationId: location.id,
        hallId: hall.id,
        purpose: data.hire.purpose,
        duration,
        schedule,
        total: parseInt(total),
        deposit: parseInt(hall.deposit),
      },
    };

    draftHireEntity({ variables: { ...input } });
  };

  useEffect(() => {
    if (duration === 0) setTotal(0);
    if (schedule.length === 0) setTotal(0);

    if (duration > 0 && schedule.length > 0) {
      const rate = hall.price * (duration / 60);
      const qty = schedule.length;
      const amount = rate * qty;

      setTotal(amount + hall.deposit);
    } else setTotal(0);
  }, [hall, duration, schedule]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* text purpose */}
        <div className="basis-full">
          <label className="label">
            <span className="label-text font-semibold">Purpose</span>
          </label>
          <textarea
            className="textarea h-20 textarea-bordered bg-white w-full"
            placeholder="Enter purpose and preferences for the training"
            {...register("hire.purpose", {
              required: true,
            })}
          />
        </div>
        {/* checkbox terms */}
        <div className="flex mt-4">
          <label className="cursor-pointer label">
            <input
              type="checkbox"
              className="checkbox"
              {...register("hire.terms", {
                required: true,
              })}
            />
            <span className="ml-2 label-text font-medium">
              By submitting this request, I confirm that I've read and I agree
              to the terms and conditions of hall hiring set by Swastik Dance
              Academy (
              <a
                href="/files/hire-terms.pdf"
                target="_blank"
                aria-label="read the terms"
                className="underline underline-offset-2"
              >
                click here
              </a>{" "}
              to read to the document). I understand that once the payment is
              confirmed, the hall will be booked for the selected duration and
              time. I also understand that any damages to the property will be
              charged to me as per the terms.
            </span>
          </label>
        </div>
        <div className="flex flex-col justify-center items-center">
          <label className="label">
            <span className="label-text font-semibold">
              Cost:{" "}
              {formatter.format((total === 0 ? 0 : total - hall.deposit) / 100)}{" "}
              + Refundable Deposit: {formatter.format(hall.deposit / 100)}
            </span>
          </label>
        </div>
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={!isValid}
        >
          Finalise Hire, Pay {formatter.format(total / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Clear
      </button>
    </>
  );
}
