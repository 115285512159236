import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { StudentSelector } from "../library/selector/Student";
import { TermSelector } from "../library/selector/Term";
import { PassSelector } from "../library/selector/Pass";
import { ClassSelector } from "../library/selector/Class";
import { VoucherSelector } from "../library/selector/Voucher";
import { DRAFT_ENTITY, USE_ENTITY } from "../utils/mutations";
import { APPLICABLE_SESSIONS, PASSES_LIST } from "../utils/queries";
import { formatter } from "../utils/helpers";

export default function Pass() {
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [pass, setPass] = useState(null);
  const [classInfo, setClassInfo] = useState(null);
  const [category, setCategory] = useState("DROPIN");
  const [canAddPass, setCanAddPass] = useState(true);

  const getGroupIds = (groups) => groups.map((group) => group.id);
  const resetClassInfo = () => setClassInfo(null);
  const resetPass = () => setPass(null);

  const clear = () => {
    setStudent(null);
    setTerm(null);
    setPass(null);
    setClassInfo(null);
    setCategory("DROPIN");
    setCanAddPass(true);
  };

  useEffect(() => {
    if (student) {
      setClassInfo(null);
      setPass(null);
    }
  }, [student]);

  return (
    <UserLayout title="Class Pass | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Class Pass"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            <strong>Drop-in pass:</strong> can be used to attend a single class
            session.
          </p>
          <p>
            <strong>Unlimited pass:</strong> can be used to attend multiple
            class sessions over the course of a term.
          </p>
          <p>
            <i>
              Please note that all passes are valid only upto the applicable
              term end date. Profile picture is mandatory for student
              enrolments.
            </i>
          </p>
        </SectionInfo>
        {/* student */}
        <StudentSelector
          selection={student}
          setSelection={setStudent}
          type="pass"
        />
        {student && (
          <TermSelector selection={term} setSelection={setTerm} type="pass" />
        )}
        {/* passes */}
        {student && term && (
          <PassSelector
            term={term}
            student={student}
            setTerm={setTerm}
            selection={pass}
            setSelection={setPass}
            type="pass"
            category={category}
            setCategory={setCategory}
            canAddPass={canAddPass}
            setCanAddPass={setCanAddPass}
          />
        )}
        {/* classes */}
        {term && pass && (
          <ClassSelector
            term={term}
            setTerm={setTerm}
            student={student}
            groupsId={getGroupIds(student.groups)}
            selection={classInfo}
            setSelection={setClassInfo}
            type="pass"
          />
        )}
        {/* add pass form */}
        {student && term && canAddPass && !classInfo && !pass && (
          <AddPassForm
            category={category}
            term={term}
            student={student}
            clear={clear}
          />
        )}
        {/* use pass form */}
        {term && student && classInfo && pass && (
          <UsePassFrom
            category={category}
            term={term}
            student={student}
            pass={pass}
            classInfo={classInfo}
            resetClassInfo={resetClassInfo}
            resetPass={resetPass}
            clear={clear}
          />
        )}
        {/* recent */}
      </section>
    </UserLayout>
  );
}

function AddPassForm(props) {
  const { category, term, student, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [total, setTotal] = useState(0);
  const [charge, setCharge] = useState(0);
  const [voucher, setVoucher] = useState(null);

  const { register, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onChange",
  });

  const useVoucher = watch("pass.useVoucher");

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();
        if (charge > 0) {
          // navigate to the transactions page with the transaction id
          navigate(`/transactions/${data.transaction}`);
        } else {
          toast.success("Pass created successfully!");
          navigate(`/home`);
        }
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = () => {
    const input = {
      type: "PASS",
      pass: {
        userId: user.data.id,
        studentId: student.id,
        termId: term.id,
        category,
        total: parseInt(charge),
        voucher: voucher ? parseInt(voucher.amount) : 0,
        vouchers: voucher ? [voucher.id] : [],
      },
    };

    draftEntity({ variables: { ...input } });
  };

  useEffect(() => {
    if (term) {
      if (category === "DROPIN") {
        setTotal(term.dropIn);
        if (useVoucher) setValue("pass.useVoucher", false);
      } else if (category === "UNLIMITED") {
        setTotal(term.unlimited);
        if (voucher) setValue("pass.useVoucher", true);
      }
    }
  }, [term, category, setValue, useVoucher, voucher]);

  // calculate charge (based on total and voucher.amount)
  useEffect(() => {
    if (voucher && voucher.amount && voucher.amount > 0) {
      const amount = total - voucher.amount;
      setCharge(amount < 0 ? 0 : amount);
    } else setCharge(total);
  }, [total, voucher]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* voucher info */}
        {student?.age >= 4 &&
          student?.age <= 18 &&
          category === "UNLIMITED" && (
            <div>
              {/* checkbox for useVoucher */}
              <div className="flex gap-4">
                <label className="cursor-pointer label">
                  <input
                    type="checkbox"
                    className="checkbox"
                    {...register("pass.useVoucher")}
                    disabled={total <= 0 || voucher}
                  />
                  <span className="label-text font-semibold pl-3">
                    I would like to redeem an NSW Active Kids Creative Kids
                    voucher
                  </span>
                </label>
              </div>
              {useVoucher && (
                <VoucherSelector
                  userId={user.data.id}
                  studentId={student.id}
                  type={"Pass"}
                  category={"AKCK"}
                  total={total}
                  voucher={voucher}
                  setVoucher={setVoucher}
                />
              )}
            </div>
          )}
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={(useVoucher && !voucher) || loading}
        >
          Finalise Pass, Pay {formatter.format(charge / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Cancel
      </button>
    </>
  );
}

function UsePassFrom(props) {
  const {
    category,
    term,
    student,
    pass,
    classInfo,
    resetClassInfo,
    resetPass,
    clear,
  } = props;

  const [sessions, setSessions] = useState([]);

  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const [getApplicableSessions] = useLazyQuery(APPLICABLE_SESSIONS, {
    onCompleted: (data) => {
      setSessions(data.sessions);
    },
    onError: (error) => {
      toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const [usingEntity, { loading }] = useMutation(USE_ENTITY, {
    onCompleted: (data) => {
      if (data.entity) {
        clear();
        reset();

        toast.success("Pass utlised successfully!");
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
    refetchQueries: [
      {
        query: PASSES_LIST,
        variables: { studentId: student.id, termId: term.id, category },
      },
    ],
  });

  const onSubmit = (data) => {
    const { session } = data.pass;

    const input = {
      type: "PASS",
      entityId: pass.id,
      pass: {
        classId: classInfo.id,
        sessionId: session,
      },
    };

    usingEntity({ variables: { ...input } });
  };

  useEffect(() => {
    if (!!classInfo) {
      getApplicableSessions({
        variables: {
          classId: classInfo.id,
          studentId: student.id,
          status: ["ONGOING", "UPCOMING"],
        },
      });
    } else {
      setSessions([]);
    }
  }, [classInfo, student, getApplicableSessions]);

  return (
    <div className="relative w-full">
      {sessions && sessions.length > 0 ? (
        <>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full flex flex-col gap-4"
          >
            {/* sessions (select) */}
            <div>
              <label className="label">
                <span className="label-text font-semibold">Select Session</span>
              </label>
              <select
                className="select select-bordered bg-white w-full"
                {...register("pass.session", {
                  required: true,
                  validate: (value) => {
                    // if "" then not valid
                    if (!value) return false;
                    // if value is in sessions then valid
                    return !!sessions.find((session) => session.id === value);
                  },
                })}
              >
                <option value="">
                  {category === "DROPIN"
                    ? "Select a session"
                    : "Select from session"}
                </option>
                {sessions.map((session) => (
                  <option key={session.id} value={session.id}>
                    {session.name} (
                    {new Date(session.date).toLocaleDateString("en-AU")})
                  </option>
                ))}
              </select>
            </div>
            {/* submit */}
            <button
              type="submit"
              className={clsx("btn btn-primary", loading && "loading")}
            >
              Use {category === "DROPIN" ? `drop-in pass` : `unlimited pass`}
            </button>
          </form>
        </>
      ) : (
        <div className="w-full flex justify-center p-4">
          <p>No class sessions available</p>
        </div>
      )}
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          resetClassInfo();
          resetPass();
        }}
      >
        Clear
      </button>
    </div>
  );
}
