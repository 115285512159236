import { useEffect } from "react";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SCHEDULE_FILTER } from "../utils/queries";
import { GET_CLASSES } from "../utils/mutations";
import { useQuery, useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { ScheduleFilter } from "../library/forms/Schedule";
import { ClassesGrid } from "../library/grid/Classes";
import { Link } from "react-router-dom";

function Schedule() {
  const notifyError = () => toast.error("Something went wrong!");
  const notifyLoading = () => toast.loading("Loading...");

  const {
    loading: filterLoading,
    data: filterData,
    error: filterError,
  } = useQuery(SCHEDULE_FILTER);

  const [
    getClassSchedule,
    { loading: scheduleLoading, data: scheduleData, error: scheduleError },
  ] = useMutation(GET_CLASSES);

  useEffect(() => {
    if (filterLoading || scheduleLoading) notifyLoading();
  }, [filterLoading, scheduleLoading]);

  useEffect(() => {
    if (filterData || scheduleData) {
      toast.dismiss();
    }
  }, [filterData, scheduleData]);

  useEffect(() => {
    if (filterError || scheduleError) notifyError();
  }, [filterError, scheduleError]);

  return (
    <UserLayout title="Classes Schedule | Swastik App">
      <section>
        <SectionHead heading="Schedule" />
        <p className="pt-4">
          You can check the class schedule here by selecting the{" "}
          <strong>Term,</strong> <strong>Studio,</strong>{" "}
          <strong>Program,</strong> and <strong>Group</strong>. To{" "}
          <strong>enrol</strong> for a class, please{" "}
          <Link to="/login" className="font-bold text-primary">
            login
          </Link>{" "}
          or{" "}
          <Link to="/register" className="font-bold text-primary">
            create an account.
          </Link>
        </p>
        {filterData && (
          <ScheduleFilter
            filters={filterData.filters}
            populate={getClassSchedule}
          />
        )}
        {scheduleData && <ClassesGrid schedule={scheduleData} />}
      </section>
    </UserLayout>
  );
}

export default Schedule;
