import { gql } from "@apollo/client";

export const GET_CLASSES = gql`
  mutation Schedule($input: scheduleFilter) {
    schedule(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
    }
  }
`;

export const GET_AVAILABLE_CLASSES = gql`
  mutation Schedule($input: scheduleFilter) {
    schedule(input: $input) {
      id
      name
      price
      schedule
      remaining
      preassessment
    }
  }
`;

export const ADD_STUDENT = gql`
  mutation CreateStudent($input: studentProfile) {
    student: createStudent(input: $input)
  }
`;

export const UPDATE_STUDENT = gql`
  mutation UpdateStudent($input: studentProfile) {
    student: updateStudent(input: $input)
  }
`;

export const DELETE_STUDENT = gql`
  mutation RemoveStudent($input: studentProfile) {
    removeStudent(input: $input)
  }
`;

export const UPDATE_PROFILE = gql`
  mutation Update($input: userProfile) {
    update(input: $input)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Change($password: String) {
    change(password: $password)
  }
`;

export const REDEEM_VOUCHER = gql`
  mutation RedeemVoucher(
    $userId: ID!
    $category: String!
    $code: String!
    $total: Int!
    $studentId: ID!
    $type: String
    $location: String
  ) {
    redeemed: redeemVoucher(
      userId: $userId
      category: $category
      code: $code
      total: $total
      studentId: $studentId
      type: $type
      location: $location
    ) {
      message
      payload
      success
    }
  }
`;

export const VOID_VOUCHER = gql`
  mutation VoidVoucher($voucherId: ID) {
    voided: voidVoucher(voucherId: $voucherId) {
      success
      message
      payload
    }
  }
`;

export const REQUEST_ENTITY = gql`
  mutation RequestEntity(
    $type: String!
    $training: TrainingDetails
    $performance: PerformanceDetails
  ) {
    entity: requestEntity(
      type: $type
      training: $training
      performance: $performance
    )
  }
`;

export const DRAFT_ENTITY = gql`
  mutation DraftEntity(
    $type: String!
    $enrolment: EnrolmentDetails
    $pass: PassDetails
    $order: OrderDetails
    $training: TrainingDetails
    $performance: PerformanceDetails
    $hire: HireDetails
    $admission: AdmissionDetails
  ) {
    transaction: draftEntity(
      type: $type
      enrolment: $enrolment
      pass: $pass
      order: $order
      training: $training
      performance: $performance
      hire: $hire
      admission: $admission
    )
  }
`;

export const CANCEL_ENTITY = gql`
  mutation CancelEntity($type: String!, $entityId: ID!) {
    entity: cancelEntity(type: $type, entityId: $entityId)
  }
`;

export const USE_ENTITY = gql`
  mutation UseEntity($type: String!, $entityId: ID!, $pass: PassDetails) {
    entity: useEntity(type: $type, entityId: $entityId, pass: $pass)
  }
`;
