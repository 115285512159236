import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import clsx from "clsx";
import toast from "react-hot-toast";
import { AuthContext } from "../context/authContext";
import { UserLayout } from "../layouts/User";
import { SectionHead } from "../library/sections/Heading";
import { SectionInfo } from "../library/sections/Information";
import { StudentSelector } from "../library/selector/Student";
import { TermSelector } from "../library/selector/Term";
import { ClassSelector } from "../library/selector/Class";
import { VoucherSelector } from "../library/selector/Voucher";
import { DRAFT_ENTITY } from "../utils/mutations";
import { formatter } from "../utils/helpers";

export default function Enrolment() {
  const [student, setStudent] = useState(null);
  const [term, setTerm] = useState(null);
  const [classInfo, setClassInfo] = useState(null);

  const getGroupIds = (groups) => groups.map((group) => group.id);

  const clear = () => {
    setStudent(null);
    setTerm(null);
    setClassInfo(null);
  };

  useEffect(() => {
    if (student) {
      setClassInfo(null);
    }
  }, [student]);

  useEffect(() => {
    if (term) {
      setClassInfo(null);
    }
  }, [term]);

  return (
    <UserLayout title="Class Enrolment | Swastik App">
      <section>
        {/* heading */}
        <SectionHead heading="Class Enrolment"></SectionHead>
        {/* information */}
        <SectionInfo>
          <p>
            <strong>Enrolment:</strong> can be used to enrol for all remaining
            class sessions. Profile picture is mandatory for student enrolments.
          </p>
          <p>
            <i>
              Please note that all enrolments are final and non-transferable.
            </i>
          </p>
        </SectionInfo>
        {/* student */}
        <StudentSelector
          selection={student}
          setSelection={setStudent}
          type="enrolment"
        />
        {/* term */}
        {student && (
          <TermSelector
            selection={term}
            setSelection={setTerm}
            type="enrolment"
          />
        )}
        {/* classes */}
        {student && term && (
          <ClassSelector
            groupsId={getGroupIds(student.groups)}
            student={student}
            term={term}
            setTerm={setTerm}
            selection={classInfo}
            setSelection={setClassInfo}
            type="enrolment"
          />
        )}
        {/* add enrolment form */}
        {student && term && classInfo && (
          <AddEnrolmentForm
            student={student}
            term={term}
            classInfo={classInfo}
            clear={clear}
          />
        )}
      </section>
    </UserLayout>
  );
}

function AddEnrolmentForm(props) {
  const { student, term, classInfo, clear } = props;

  const { user } = useContext(AuthContext);

  const navigate = useNavigate();

  const [total, setTotal] = useState(0);
  const [charge, setCharge] = useState(0);
  const [voucher, setVoucher] = useState(null);

  const { register, handleSubmit, reset, watch } = useForm({
    mode: "onChange",
  });

  const useVoucher = watch("enrolment.useVoucher");

  const [draftEntity, { loading }] = useMutation(DRAFT_ENTITY, {
    onCompleted: (data) => {
      if (data.transaction) {
        clear();
        reset();

        if (charge > 0) {
          // navigate to the transactions page with the transaction id
          navigate(`/transactions/${data.transaction}`);
        } else {
          toast.success("Enrolment successful.");
          navigate("/home");
        }
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to proceed with the request. Please try again later."
      );
    },
  });

  const onSubmit = () => {
    const input = {
      type: "ENROLMENT",
      enrolment: {
        userId: user.data.id,
        studentId: student.id,
        termId: term.id,
        classId: classInfo.id,
        total: parseInt(charge),
        voucher: voucher ? parseInt(voucher.amount) : 0,
        vouchers: voucher ? [voucher.id] : [],
      },
    };

    draftEntity({ variables: { ...input } });
  };

  useEffect(() => {
    const total = classInfo.sessionsAvailable * classInfo.price;
    setTotal(total < 0 ? 0 : total);
  }, [classInfo]);

  // calculate charge (based on total and voucher.amount)
  useEffect(() => {
    if (voucher && voucher.amount && voucher.amount > 0) {
      const amount = total - voucher.amount;
      setCharge(amount < 0 ? 0 : amount);
    } else setCharge(total);
  }, [total, voucher]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
        {/* voucher info */}
        {student?.age >= 4 && student?.age <= 18 && (
          <div>
            {/* checkbox for useVoucher */}
            <div className="flex gap-4">
              <label className="cursor-pointer label">
                <input
                  type="checkbox"
                  className="checkbox"
                  {...register("enrolment.useVoucher")}
                  disabled={total <= 0 || voucher}
                />
                <span className="label-text font-semibold pl-3">
                  I would like to redeem an NSW Active Kids Creative Kids
                  voucher
                </span>
              </label>
            </div>
            {useVoucher && (
              <VoucherSelector
                userId={user.data.id}
                studentId={student.id}
                type={"Enrolment"}
                category={"AKCK"}
                total={total}
                voucher={voucher}
                setVoucher={setVoucher}
                location={classInfo.location}
              />
            )}
          </div>
        )}
        {/* submit */}
        <button
          type="submit"
          className={clsx("btn btn-primary mt-6", loading && "loading")}
          disabled={(useVoucher && !voucher) || loading}
        >
          Finalise Enrolment, Pay {formatter.format(charge / 100)}
        </button>
      </form>
      <button
        className="btn btn-outline w-full mt-2"
        onClick={() => {
          reset();
          clear();
        }}
      >
        Clear
      </button>
    </>
  );
}
