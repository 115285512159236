import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useLazyQuery } from "@apollo/client";
import clsx from "clsx";
import toast from "react-hot-toast";
import { DROPPED_VOUCHER, VOUCHER_INFO } from "../../utils/queries";
import { REDEEM_VOUCHER, VOID_VOUCHER } from "../../utils/mutations";
import { formatter } from "../../utils/helpers";

export const VoucherSelector = (props) => {
  const {
    userId,
    studentId,
    type,
    category,
    total,
    voucher,
    setVoucher,
    location,
  } = props;

  const [redeemedVoucher, setRedeemedVoucher] = useState(null);

  const [checkDroppedVoucher] = useLazyQuery(DROPPED_VOUCHER, {
    variables: { userId, studentId, category, total },
    onCompleted: (data) => {
      if (data?.response?.success) {
        setRedeemedVoucher(data.response.payload);
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error(
        "Unable to initialise voucher information! Please try again."
      );
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!redeemedVoucher && !voucher) checkDroppedVoucher();
  }, [redeemedVoucher, voucher, checkDroppedVoucher]);

  return (
    <div className="flex flex-col gap-4">
      {/* input group */}
      {/* for AKCK */}
      {category === "AKCK" && !redeemedVoucher && (
        <AKCKVoucherForm
          userId={userId}
          studentId={studentId}
          type={type}
          total={total}
          redeemedVoucher={redeemedVoucher}
          setRedeemedVoucher={setRedeemedVoucher}
          location={location}
        />
      )}
      {/* details */}
      {redeemedVoucher && (
        <VoucherSummary
          id={redeemedVoucher}
          voucher={voucher}
          setVoucher={setVoucher}
          clear={() => setRedeemedVoucher(null)}
        />
      )}
    </div>
  );
};

function AKCKVoucherForm(props) {
  const {
    userId,
    studentId,
    type,
    total,
    redeemedVoucher,
    setRedeemedVoucher,
    location,
  } = props;

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const enteredCode = watch("voucher.akck");

  const [redeemVoucher, { loading: redeeming }] = useMutation(REDEEM_VOUCHER, {
    onCompleted: (data) => {
      if (data.redeemed) {
        const { success, payload, message } = data.redeemed;

        if (!success) toast.error(message);
        else {
          setRedeemedVoucher(payload);
          toast.success(message);
          reset({ voucher: { akck: "" } });
        }
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to redeem voucher! Please try again.");
    },
  });

  const handleClear = (e) => {
    e.preventDefault(); // prevent form submission
    reset({ voucher: { akck: "" } });
  };

  const onSubmit = (data) => {
    const input = {
      userId: userId,
      studentId: studentId,
      type: type,
      category: "AKCK",
      code: data.voucher.akck,
      total: total,
      ...(location && { location: location.name }),
    };

    redeemVoucher({ variables: { ...input } });
  };

  return (
    <div className="flex flex-col sm:flex-row gap-2">
      <div className="sm:grow flex flex-col relative pt-2">
        {/* label */}
        <label className="label sr-only">
          <span className="label-text font-semibold">
            Active Kids Creative Kids Voucher Code
          </span>
        </label>
        {/* input */}
        <input
          type="text"
          placeholder="Enter 10-digit AKCK code"
          className={clsx(
            "input input-bordered w-full bg-white font-mono tracking-widest",
            errors.voucher?.akck && "input-error"
          )}
          // 10 digit alphanumeric code
          pattern="^[a-zA-Z0-9]{10}$"
          {...register("voucher.akck", {
            required: "Voucher code is required",
            pattern: {
              value: /^[a-zA-Z0-9]{10}$/,
              message: "Voucher code must be 10 characters long",
            },
          })}
          minLength={10}
          maxLength={10}
          disabled={redeemedVoucher || redeeming}
        />
        {enteredCode && (
          <input
            type="reset"
            value="Clear"
            alt="Clear the search form"
            className="absolute top-3 right-0 mt-2 mr-2 btn btn-xs btn-ghost"
            disabled={redeemedVoucher || redeeming}
            onClick={handleClear}
          />
        )}
        {/* error message */}
        {errors.voucher?.akck && (
          <small className="text-xs text-red-500 p-1 w-full text-center">
            {errors.voucher.akck.message}
          </small>
        )}
        {/* note */}
        <div>
          <span className="text-xs text-gray-500 px-1">
            Enter the 10-digit voucher code to redeem the discount.
          </span>
        </div>
      </div>
      {/* submit */}
      <div className="pt-2">
        <button
          className={clsx(
            "btn w-full btn-outline disabled:bg-transparent",
            redeeming && "loading"
          )}
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Redeem Voucher
        </button>
      </div>
    </div>
  );
}

function VoucherSummary(props) {
  const { id, voucher, setVoucher, clear } = props;

  const [getVoucher] = useLazyQuery(VOUCHER_INFO, {
    variables: { voucherId: id },
    onCompleted: (data) => {
      setVoucher(data.voucher);
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to fetch voucher information! Please try again.");
    },
    fetchPolicy: "network-only",
  });

  const [voidVoucher, { loading: voiding }] = useMutation(VOID_VOUCHER, {
    onCompleted: (data) => {
      if (data.voided) {
        const { success, message } = data.voided;
        if (success) {
          toast.success(message);
          setVoucher(null);
          clear();
        } else {
          toast.error(message);
        }
      }
    },
    onError: (error) => {
      console.error(error);
      toast.error("Unable to void voucher! Please try again.");
    },
  });

  const handleCancellation = (e) => {
    e.preventDefault(); // prevent form submission
    window.confirm("Are you sure you want to cancel the redemption?") &&
      voidVoucher({ variables: { voucherId: id } });
  };

  useEffect(() => {
    if (id) getVoucher();
  }, [id, getVoucher]);

  return (
    <div className="flex flex-col sm:flex-row bg-white divide-y-2 sm:px-2 sm:divide-x-2 sm:divide-y-0 rounded-lg mt-2">
      {voucher && (
        <>
          <div className="grow py-4 px-4">
            <p>
              <strong>Confirmation: </strong>
            </p>
            <p className="text-sm tracking-wide">
              Used code <strong>{voucher?.code}</strong> to redeem{" "}
              <strong>{formatter.format(voucher?.amount / 100)}</strong>
            </p>
          </div>
          <div className="py-4 px-4 flex justify-center items-center">
            <button
              className={clsx(
                "btn btn-xs btn-error btn-ghost text-error",
                voiding && "loading"
              )}
              onClick={handleCancellation}
            >
              Cancel Redemption
            </button>
          </div>
        </>
      )}
    </div>
  );
}
